import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { forwardRef } from "react";

import classNames from "classnames";

const sizes = {
  medium: "px-4 py-2 rounded-md text-sm",
  large: "px-6 py-3 rounded-md text-base",
};

const variants = {
  primary: "border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500",
};

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, disabled, className, variant = "primary", size = "large", isLoading = false, ...props },
  ref,
) {
  return (
    <button
      ref={ref}
      className={classNames(
        "relative overflow-hidden transition duration-150 ease-in-out inline-flex items-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
        variants[variant],
        sizes[size],
        { "cursor-not-allowed": isLoading },
        className,
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {!!(isLoading || disabled) && (
        <div className="absolute inset-0">
          {!!isLoading && (
            <div
              aria-hidden="true"
              className="animate-loading absolute top-0 left-0 right-0 z-10 h-1 bg-blue-600"
            />
          )}
          <div className="h-full bg-white bg-opacity-50" />
        </div>
      )}
      {children}
    </button>
  );
});

export default Button;
