import type { UseMutationOptions, UseQueryOptions } from "react-query/types/react/types";

import Cookies from "js-cookie";
import { useMutation, useQuery, useQueryClient } from "react-query";

import Http from "lib/http";

type User = { id: string; name: string; email: string };

export function useUser(options?: UseQueryOptions<User>) {
  return useQuery(
    useUser.queryKey(),
    async () => {
      const response = await Http().get<User>("/api/user");

      return response.data;
    },
    options,
  );
}

useUser.queryKey = () => ["User"];

export function useLogin(options?: UseMutationOptions<{ token: string }, unknown, unknown, unknown>) {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { email: string; password: string }) => {
      const response = await Http({ authorized: false }).post(`/api/login`, values);

      return response.data;
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        Cookies.set("authorization", `Bearer ${data.token}`);
        queryClient.invalidateQueries(useUser.queryKey());
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useLogout(options?: UseMutationOptions<unknown, unknown, unknown, unknown>) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await Http().post(`/api/logout`);

      return response.data;
    },
    {
      onSuccess: (data, variables, context) => {
        Cookies.remove("authorization");

        options?.onSuccess?.(data, variables, context);

        queryClient.invalidateQueries(useUser.queryKey());
      },
    },
  );
}

export function useUpdateUser(options?: UseMutationOptions<unknown, unknown, unknown, unknown>) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ name }: { name: string }) => {
      const response = await Http().patch(`/api/user`, { name });

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("User");
      },
      ...options,
    },
  );
}
