import type { Control, FieldValues } from "react-hook-form/dist/types";
import type { FieldPath } from "react-hook-form/dist/types/path";

import { useController } from "react-hook-form";

export default function FieldErrorMessage<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
}: {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}) {
  const { fieldState } = useController({ name, control });
  // form.formState needs to be passed to activate the state proxy
  const error = fieldState.error;

  if (!error || !error?.message) {
    return null;
  }

  return (
    <p className="mt-2 text-sm text-red-600" id={`${name.toString()}-error`} data-error="true">
      {error.message}
    </p>
  );
}
