import type { GetServerSidePropsContext } from "next";

import axios from "axios";
import Cookies from "js-cookie";

type HttpOptions = {
  authorized: boolean;
};

export default function Http(options: HttpOptions = { authorized: true }) {
  return axios.create({
    withCredentials: true,
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: options.authorized
      ? {
          Authorization: Cookies.get("authorization"),
        }
      : undefined,
  });
}

export function ServerSideHttpClient(
  context: GetServerSidePropsContext,
  options: HttpOptions = { authorized: true },
) {
  return axios.create({
    withCredentials: true,
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: options.authorized
      ? {
          Authorization: context.req.cookies.authorization,
        }
      : undefined,
  });
}
