import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";

import classNames from "classnames";

const Input = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    id: string;
    leftElement?: ReactNode;
    rightElement?: ReactNode;
    error?: string;
  }
>(function Input({ className, error, id, leftElement, rightElement, type, disabled, ...props }, ref) {
  const isInvalid = !!error;

  return (
    <div className={classNames("relative flex rounded-md shadow-sm", className)}>
      {!!leftElement && leftElement}
      <input
        ref={ref}
        id={id}
        type={type ?? "text"}
        className={classNames("flex-1 block w-full", {
          "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md":
            isInvalid,
          "focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md": !isInvalid,
          "rounded-l-none": !!leftElement,
          "rounded-r-none": !!rightElement,
          "bg-gray-50": disabled,
        })}
        aria-invalid={isInvalid ? "true" : undefined}
        aria-describedby={isInvalid ? `${id}-error` : undefined}
        disabled={disabled}
        {...props}
      />
      {!!rightElement && rightElement}
      {!!isInvalid && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
  );
});

export default Input;
