import type { GetServerSidePropsContext } from "next";

import Link from "next/link";
import { useRouter } from "next/router";

import { useForm } from "react-hook-form";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

import Button from "components/Button";
import FieldErrorMessage from "components/FieldErrorMessage";
import Input from "components/Input";
import Title from "components/Title";

import { useLogin } from "queries/admin/user";

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const queryClient = new QueryClient();

  if (context.req.cookies.authorization) {
    return {
      redirect: {
        destination: "/",
      },
    };
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

type Values = {
  email: string;
  password: string;
};

export default function Login() {
  const router = useRouter();

  const { control, register, handleSubmit, setError } = useForm<Values>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate: login, isLoading } = useLogin({
    onSuccess: () => {
      router.push("/");
    },
    onError: () => {
      setError("email", { message: "E-mailadres en wachtwoord combinatie onjuist" });
    },
  });

  return (
    <>
      <Title>Inloggen</Title>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-20 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
              <span className="font-black uppercase text-blue-600 text-3xl">CARDASH</span>
            </div>

            <form
              onSubmit={handleSubmit(values => {
                login(values);
              })}
              className="space-y-6"
            >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  E-mailadres
                </label>
                <div className="mt-1">
                  <Input id="email" type="email" autoComplete="email" required {...register("email")} />
                  <FieldErrorMessage control={control} name="email" />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Wachtwoord
                </label>
                <div className="mt-1">
                  <Input
                    id="password"
                    autoComplete="current-password"
                    type="password"
                    required
                    {...register("password")}
                  />
                  <FieldErrorMessage control={control} name="password" />
                </div>
              </div>

              <div>
                <Button
                  size="large"
                  isLoading={isLoading}
                  className="w-full flex items-center justify-center"
                  type="submit"
                >
                  Inloggen
                </Button>
              </div>

              <Link href="/password/request">
                <a className="block text-center text-sm font-medium text-blue-600 hover:text-blue-500 mt-4">
                  Wachtwoord vergeten?
                </a>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
